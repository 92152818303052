
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MTableConditionItem from "@/components/MTableConditionItem.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MTextField from "@/components/form/MTextField.vue";
import MsMultiRunDropDown from "@/components/student/MsMultiRunDropDown.vue";
import MSAddModal from "@/components/student/MSAddModal.vue";
import MsSendModal from "@/components/student/MsSendModal.vue";
import MsAddGroupModal from "@/components/student/MsAddGroupModal.vue";
import MsReserveModal from "@/components/student/MsReserveModal.vue";
import MSMessagePanel from "@/components/student/MSMessagePanel.vue";
import MqQuestionLogItem from "@/components/qanda/MqQuestionLogItem.vue";
import { Classroom } from "@/entities/classroom";
import { Student } from "@/entities/student";
import { Options, Vue } from "vue-class-component";
import { School } from "@/entities/school";
import { CustomProperty } from "@/entities/custom_property";
import { Group } from "@/entities/group";
import { RunningRegistrant } from "@/entities/room";
import { RunningTimer } from "@/entities/learning";
import { Getter, State } from "@/store/helper";
import { QandaChatHistoryData } from "../entities/qanda";
import { getStudentAndQandaChatHistory } from "@/api/qanda";
import { QandaMessage } from "../entities/qanda";
import { Breadcrumb } from "../components/MHeaderPanel.vue";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MTableConditionItem,
    MNavBar,
    MHeaderPanel,
    MTextField,
    MsMultiRunDropDown,
    MSAddModal,
    MsSendModal,
    MsAddGroupModal,
    MsReserveModal,
    MSMessagePanel,
    MqQuestionLogItem
  }
})
export default class QandaQuestionLog extends Vue {
  student: Student | null = null;
  history: QandaChatHistoryData | null = null;
  messages: QandaMessage[] | [] = [];

  loading = false;

  @State("baseDatas", "studentList") baseDatas!: {
    [key: string]: string | number | boolean;
  }[];
  @State("beforeLoad", "studentList") beforeLoad!: boolean;
  @State("learningTerm", "studentList") learningTerm!: string;
  @State("termStart", "studentList") termStart!: string;
  @State("termStartTime", "studentList") termStartTime!: number;
  @State("termEnd", "studentList") termEnd!: string;
  @State("termEndTime", "studentList") termEndTime!: number;
  @State("runningRoomRegistrants", "studentList")
  runningRoomRegistrants!: RunningRegistrant[];
  @State("runningTimers", "studentList") runningTimers!: RunningTimer[];

  @State("schools") schools!: School[];
  @State("classrooms") classrooms!: Classroom[];
  // @State("students") students!: Student[];
  @State("groups") groups!: Group[];
  @State("customProperties") customProperties!: CustomProperty[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;

  get breadcrumbs(): Breadcrumb[] {
    return [
      {
        text: "生徒一覧",
        link: "/student"
      },
      {
        text: "QANDA質問一覧",
        link: `/qanda/${this.$route.params.studentId}`
      }
    ];
  }

  async created() {
    this.loading = true;
    const historyId = this.$route.params.historyId;
    const type = this.$route.params.type;

    const { student, qandaChatHistory } = await getStudentAndQandaChatHistory(
      this.$route.params.studentId as string
    );
    this.student = student;
    const histories = qandaChatHistory?.data ?? [];
    this.history =
      histories.find(m => m.id == historyId && m.type === type) ?? null;
    this.messages =
      this.history?.messages.sort(
        (a, b) =>
          new Date(a.createTime).getTime() - new Date(b.createTime).getTime()
      ) ?? [];
    this.loading = false;
  }
}
