import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "question-container" }
const _hoisted_2 = { class: "rounded-md bg-gray-200 w-11/12" }
const _hoisted_3 = { class: "w-full p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.messages, (msg, index) => {
      return (_openBlock(), _createBlock("div", {
        key: index,
        class: {
        'mb-8 flex flex-col': index < _ctx.messages.length - 1,
        'mb-0 flex flex-col': index === _ctx.messages.length - 1,
        'items-end': _ctx.isTeacher(msg.sender)
      }
      }, [
        _createVNode("div", {
          class: [
          'flex gap-2 items-center mb-2',
          _ctx.isTeacher(msg.sender) ? 'flex-row-reverse' : ''
        ]
        }, [
          _createVNode("div", null, [
            _createVNode("img", {
              src: _ctx.getIconUrl(_ctx.isStudent(msg.sender)),
              alt: "",
              class: ['w-8 h-8', _ctx.isStudent(msg.sender) ? 'rounded-full' : '']
            }, null, 10, ["src"])
          ]),
          _createVNode("div", null, _toDisplayString(_ctx.isStudent(msg.sender) ? _ctx.student.data.name : msg.sender), 1),
          _createVNode("time", null, _toDisplayString(_ctx.formatIsoDate(msg.createTime)), 1)
        ], 2),
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            (msg.contentType === 'TEXT')
              ? (_openBlock(), _createBlock(_component_Markdown, {
                  key: 0,
                  class: "mb-4 whitespace-pre-wrap",
                  source: msg.content,
                  plugins: _ctx.plugins
                }, null, 8, ["source", "plugins"]))
              : (msg.contentType === 'IMAGE')
                ? (_openBlock(), _createBlock("div", {
                    key: 1,
                    class: [
              'w-full flex',
              _ctx.isTeacher(msg.sender) ? 'flex-row-reverse' : ''
            ]
                  }, [
                    _createVNode("img", {
                      src: msg.content,
                      alt: "image",
                      class: "w-160 md:w-160"
                    }, null, 8, ["src"])
                  ], 2))
                : _createCommentVNode("", true)
          ])
        ])
      ], 2))
    }), 128))
  ]))
}