
import { Options, Vue } from "vue-class-component";
import { Student } from "@/entities/student";
import { QandaMessage } from "@/entities/qanda";
import Markdown from "vue3-markdown-it";
import mk from "markdown-it-katex";

@Options({
  props: {
    messages: Array,
    student: Object
  },
  components: {
    Markdown
  }
})
export default class MqQuestionLogItem extends Vue {
  loading = false;
  tempArray = ["student", "tutor", "student"];
  student!: Student;
  messages!: QandaMessage[];

  teacherRoles = ["TEACHER", "PROBLEM_SOLVER", "ENGLISH_TUTOR"];
  studentRoles = ["STUDENT"];

  plugins = [{ plugin: mk }];

  isTeacher(senderType: string) {
    return this.teacherRoles.includes(senderType);
  }
  isStudent(senderType: string) {
    return this.studentRoles.includes(senderType);
  }
  getIconUrl(isStudent: boolean) {
    if (isStudent) {
      return require("@/assets/student-icon.svg");
    } else {
      return require("@/assets/qanda-icon.png");
    }
  }

  formatIsoDate(isoDate: string): string {
    const date = new Date(isoDate);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const zeroPad = (num: number) => num.toString().padStart(2, "0");
    return `${year}/${zeroPad(month)}/${zeroPad(day)} ${zeroPad(
      hours
    )}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
  }
}
